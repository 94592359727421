<template>
<div>
</div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Dashboard",
  components: {
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  async created() {
  },

  methods: {

  },

  computed: {
    ...mapState("auth", ["user"]),
    isLoggedIn() {
      return this.$store.getters["auth/isLoggedIn"];
    },
  },
};
</script>
<style></style>
